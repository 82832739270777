import React from "react";
import classnames from "classnames";

const TaskerReviewsPagination = ({
  page,
  changePage,
  shownPages,
  totalPages,
}) => (
  <div className="pagination">
    {/* [MEADOW_TODO] - Button */}
    <button
      className={classnames({
        "pagination-caret": true,
        "is-disabled": page === 1,
      })}
      disabled={page === 1}
      onClick={e => changePage(page - 1)}
    >
      <i className="ss-caret-left" />
    </button>
    {shownPages.map(shownPage => {
      return shownPage === page ? (
        <span key={shownPage} className="current">
          {page}
        </span>
      ) : (
        <a
          key={shownPage}
          className="pagination-number"
          onClick={e => changePage(shownPage)}
        >
          {shownPage}
        </a>
      );
    })}
    {/* [MEADOW_TODO] - Button */}
    <button
      className={classnames({
        "pagination-caret": true,
        "is-disabled": page === totalPages,
      })}
      disabled={page === totalPages}
      onClick={e => changePage(page + 1)}
    >
      <i className="ss-caret-right" />
    </button>
  </div>
);

export default TaskerReviewsPagination;
