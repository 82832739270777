import moment from "util/momentWithTZ";

export function formatDate (
  date,
  options = {
    day: "numeric",
    month: "long",
  }
) {
  return formatWithTimeZone(date, options);
};

export function formatTime (time, options = {
  hour: "numeric",
  minute: "numeric",
}) {
  return formatWithTimeZone(time, options);
};

function formatWithTimeZone (datetime, options) {
  let formattedDateTime;
  try {
    formattedDateTime = Intl.DateTimeFormat(window.LOCALE, {
      timeZone: window.TIME_ZONE,
      ...options,
    }).format(datetime);
  } catch (err) {
    formattedDateTime = Intl.DateTimeFormat(window.LOCALE, options).format(
      datetime
    );
  }
  return formattedDateTime;
};

const dstOffset = (date) => {
  if (
    moment(date).startOf("day").isDST() !== moment(date).endOf("day").isDST()
  ) {
    return moment(date).startOf("day").isDST() ? 3600 : -3600;
  }
  return 0;
}

export const shortDayWithWeekDay = (d) => {
  const date = formatDate(moment(d).endOf("day"), {
    day: "numeric",
    month: "short",
  });

  const weekday = formatDate(moment(d).endOf("day"), {
    weekday: "short",
  });

  return `${weekday}, ${date}`
}

export const formatTimeFromOffset = (date, offsetSeconds) => {
  const optionDate = moment(date)
    .startOf("day")
    .add(offsetSeconds + dstOffset(date), "seconds");

  return formatTime(optionDate);
}
