import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { v1 as uuidv1 } from "uuid";

defineMessages({
  star_1_label: {
    id: "star-ratings.review.label.star_1",
    defaultMessage: "1 out of 5 stars",
  },
  star_2_label: {
    id: "star-ratings.review.label.star_2",
    defaultMessage: "2 out of 5 stars",
  },
  star_3_label: {
    id: "star-ratings.review.label.star_3",
    defaultMessage: "3 out of 5 stars",
  },
  star_4_label: {
    id: "star-ratings.review.label.star_4",
    defaultMessage: "4 out of 5 stars",
  },
  star_5_label: {
    id: "star-ratings.review.label.star_5",
    defaultMessage: "5 out of 5 stars",
  },
});

const renderRating = (rating) => {
  // // [MEADOW_TODO] - StarRating
  let stars = new Array(rating);
  stars = stars.fill(1).map((star, i) => {
    return (
      <li key={uuidv1()}>
        <i className="ss-star" />
      </li>
    );
  });
  return stars;
};

const StarRatings = ({ categoryName, rating }) => (
  <strong className="category-title-container">
    {categoryName && `${categoryName}:`}
    <span className="visually-hidden">
      <FormattedMessage id={`star-ratings.review.label.star_${rating}`} />
    </span>
    <ul aria-hidden={true} className="star-rating">
      {renderRating(rating)}
    </ul>
  </strong>
);

export default StarRatings;
