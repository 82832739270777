import { TaskerProfileLayout } from "tasker/containers/TaskerProfileLayout";

export default [
  {
    path: "/:slug/category/:categoryId/",
    component: TaskerProfileLayout,
  },
  {
    path: "/:slug",
    component: TaskerProfileLayout,
  },
];
