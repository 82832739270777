import React, { Component } from "react";
import range from "lodash/range";

export default class PaginationNew extends Component {
  constructor(props) {
    super(props);
    this.totalItems = props.totalItems || props.items.length;
    this.numPages = Math.ceil(this.totalItems / props.perPage);
    this.calculateShownPages = this.calculateShownPages.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    const { items, totalItems, perPage } = this.props;
    if (items !== nextProps.items || totalItems !== nextProps.totalItems) {
      this.totalItems = nextProps.totalItems || nextProps.items.length;
      this.numPages = Math.ceil(this.totalItems / perPage);
    }
  }

  calculateShownPages() {
    const { page, numChoices } = this.props;
    const pages = this.numPages;

    if (pages <= numChoices) return range(1, pages + 1);

    const standardPad = Math.floor(numChoices / 2);

    let choicesRight = standardPad;

    if (page - standardPad <= 0) {
      choicesRight = numChoices - page;
    } else if (pages - page < standardPad) {
      choicesRight = pages - page;
    }

    const choicesLeft = standardPad * 2 - choicesRight;
    return range(page - choicesLeft, page + (choicesRight + 1));
  }

  render() {
    return this.props.children({
      shownPages: this.calculateShownPages(),
      totalPages: this.numPages,
    });
  }
}
